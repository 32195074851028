import '../styles/fonts.scss'
import '../styles/globals.css'
import '../styles/normalize.css'
import '../styles/tooltip-styles.scss'
import type { AppProps } from 'next/app'

import React from 'react'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import ErrorPage from '@/pages/error-page'
import WithYandexMetric from '@/hoc/metrika'
import SupportWidget from '@/components/support'
import TooltipsDefs from '@/components/tooltips'
import { appWithTranslation } from 'next-i18next'
import store, { wrapper } from '@/store'

import { Provider } from 'react-redux'
import Header from '@/particals/meta'
import { DataSourcesProvider } from '@/providers/data-source-provider'
import { AlertsProvider } from '@/providers/alerts'
import Modal from '@/components/modal'

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    debug: false,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_APP_ENV,
    release: process.env.NEXT_PUBLIC_RELEASE || 'NOT_SET',
    integrations: [new BrowserTracing()],

    autoSessionTracking: false
  })
}

function App({ Component, pageProps }: AppProps) {
  React.useEffect(() => {
    const favicon16El = document.querySelector('link[rel="icon"][sizes="16x16"]')
    const favicon32El = document.querySelector('link[rel="icon"][sizes="32x32"]')
    const faviconMaskEl = document.querySelector('link[rel="mask-icon"]')
    const faviconAppleEl = document.querySelector('link[rel="apple-touch-icon"]')

    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      themeChange(true)
    }

    function themeChange(isDark: boolean) {
      if (isDark) {
        // @ts-ignore
        favicon16El.setAttribute('href', '/favicon-16x16-dark.png')
        // @ts-ignore
        favicon32El.setAttribute('href', '/favicon-32x32-dark.png')
        // @ts-ignore
        faviconMaskEl.setAttribute('href', '/safari-pinned-tab-dark.svg')
        // @ts-ignore
        faviconAppleEl.setAttribute('href', '/apple-touch-icon-dark.png')
      } else {
        // @ts-ignore
        favicon16El.setAttribute('href', '/favicon-16x16.png')
        // @ts-ignore
        favicon32El.setAttribute('href', '/favicon-32x32.png')
        // @ts-ignore
        faviconMaskEl.setAttribute('href', '/safari-pinned-tab.svg')
        // @ts-ignore
        faviconAppleEl.setAttribute('href', '/apple-touch-icon.png')
      }
    }

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')

    mediaQuery.addEventListener('change', (event: { matches: any }) => themeChange(event.matches))
  }, [])

  return (
    <Sentry.ErrorBoundary fallback={({ error }) => <ErrorPage title={error.message} />}>
      <Provider store={store}>
        <DataSourcesProvider>
          <WithYandexMetric>
            <Header />
            <TooltipsDefs />
            <Modal />
            <Component {...pageProps} />
            <SupportWidget />
            <AlertsProvider />
          </WithYandexMetric>
        </DataSourcesProvider>
      </Provider>
    </Sentry.ErrorBoundary>
  )
}

export default wrapper.withRedux(appWithTranslation(App))
