import * as React from 'react'
import { SVGAttributes } from 'react'

export const MinusIcon = React.memo(
  ({
    size = 24,
    color = '#4E5361',
    ...props
  }: SVGAttributes<SVGElement> & {
    size?: number
  }) => {
    return (
      <svg width={size} height={size} viewBox='0 0 24 24' fill='none' {...props}>
        <path d='M19.5 11a1.5 1.5 0 010 3h-15a1.5 1.5 0 010-3h15z' fill={color} />
      </svg>
    )
  }
)

MinusIcon.displayName = 'MinusIcon'
