import * as React from 'react'
import { SVGAttributes } from 'react'

export const PlusIcon = React.memo(
  ({
    size = 24,
    color = '#4E5361',
    ...props
  }: SVGAttributes<SVGElement> & {
    size?: number
  }) => {
    return (
      <svg width={size} height={size} viewBox='0 0 24 24' fill='none' {...props}>
        <path
          d='M12 3a1.5 1.5 0 00-1.5 1.5v6h-6a1.5 1.5 0 000 3h6v6a1.5 1.5 0 003 0v-6h6a1.5 1.5 0 000-3h-6v-6A1.5 1.5 0 0012 3z'
          fill={color}
        />
      </svg>
    )
  }
)

PlusIcon.displayName = 'PlusIcon'
