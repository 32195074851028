import * as React from 'react'
import { SVGAttributes } from 'react'

export const DownloadIcon = React.memo(
  ({
    size = 24,
    color = '#4E5361',
    ...props
  }: SVGAttributes<SVGElement> & {
    size?: number
  }) => {
    return (
      <svg width={size} height={size} viewBox='0 0 24 24' fill='none' {...props}>
        <path
          d='M7.55 11.205a.561.561 0 01.512-.33h1.25a1 1 0 001-1V3.562c0-.31.252-.562.563-.562h2.25c.31 0 .562.252.562.563v6.312a1 1 0 001 1h1.25a.563.563 0 01.423.933l-3.937 4.5a.56.56 0 01-.846 0l-3.938-4.5a.56.56 0 01-.088-.603z'
          fill={color}
        />
        <path
          d='M4.813 15.375a1 1 0 011 1v1.375a1 1 0 001 1h10.375a1 1 0 001-1v-1.375a1 1 0 011-1h.25a1 1 0 011 1v3.5c0 .622-.505 1.125-1.125 1.125H4.688a1.124 1.124 0 01-1.125-1.125v-3.5a1 1 0 011-1h.25z'
          fill={color}
        />
      </svg>
    )
  }
)

DownloadIcon.displayName = 'DownloadIcon'
