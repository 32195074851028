import React from 'react'

import styles from './styles.module.scss'

import { Icon } from '@/components/icons/icon'
import { CommonProps } from '@/components/modal/types'
import { Text } from '@/components/text'

const Component: React.FC<CommonProps> = ({ title, closeModal, children }) => {
  return (
    <div className={styles.modal__overlay} onClick={closeModal}>
      <div className={styles.modal__inner}>
        <div className={styles.modal__window} onClick={event => event.stopPropagation()}>
          <div className={styles.modal__header}>
            <Text tag="b" variant='h6' color='white'>
              {title}
            </Text>
            <div className={styles.modal__close} onClick={closeModal}>
              <Icon name='close' color='white' size={15} />
            </div>
          </div>
          <div className={styles.modal__content}>{children}</div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Component)
