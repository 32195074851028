import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { alertEnum } from '@/types/alerts'
import { TypeModal } from '@/components/modal/types'

interface IAlertState {
  id: number
  variant: string
  type: alertEnum
  i18nKey: string
  params: {}
}

export type settings = {
  sounds: boolean
}

export interface IModalState {
  type: TypeModal | null
  isOpen?: boolean
}

export interface GlobalState {
  alerts: IAlertState[]
  settings: settings
  modal: IModalState
}

const initialState: GlobalState = {
  alerts: [],
  settings: { sounds: true },
  modal: {
    type: null,
    isOpen: false
  }
}

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    OPEN_MODAL: (state, action: PayloadAction<IModalState>) => {
      state.modal.isOpen = true
      state.modal.type = action.payload.type
    },
    CLOSE_MODAL: state => {
      state.modal.isOpen = false
      state.modal.type = null
    },
    OPEN_ALERT: (state, action: PayloadAction<any>) => {
      state.alerts = [
        {
          id:
            state.alerts.reduce(
              (maxId, alertState) => (alertState.id > maxId ? alertState.id : maxId),
              0
            ) + 1,
          variant: action.payload.variant,
          type: action.payload.type,
          i18nKey: action.payload.i18nKey,
          params: action.payload.params || {}
        },
        ...state.alerts
      ]
    },
    CLOSE_ALERT: (state, action: PayloadAction<number>) => {
      state.alerts = state.alerts.filter(alertState => alertState.id !== action.payload)
    }
  }
})

export const { OPEN_ALERT, CLOSE_ALERT, OPEN_MODAL, CLOSE_MODAL } = globalSlice.actions

export const globalReducer = globalSlice.reducer
