import React, { useMemo } from 'react'

import styles from './styles.module.scss'
import { Text } from '@/components/text'
import { Button } from '@/components/button'
import { Input } from '@/components/input'
import { useDataSourcesProvider } from '@/providers/data-source-provider'
import { themeColor } from '@/styles/theme'
import { LinkButton } from '@/components/button/link-button'

const bannerSizes = ['1000х1440', '1000х1000']

const Component = () => {
  const { api } = useDataSourcesProvider()
  const [loadingCreate, setIsLoading] = React.useState(false)
  const [code, setCode] = React.useState('')
  const [selectedBanner, setSelectedBanner] = React.useState(0)

  const createPromo = () => {
    console.log('Create promo')
    console.log({
      sizeBanner: bannerSizes[selectedBanner],
      promoCode: code
    })
  }

  const onChangePromo = React.useCallback(e => {
    return setCode((e.target.value || '').toUpperCase())
  }, [])

  const onChangeBanner = React.useCallback((id: number) => {
    return setSelectedBanner(id)
  }, [])

  const isDisabled = useMemo(() => code === '', [code])

  const activeColor = (id: number): themeColor => {
    return selectedBanner === id ? 'gold-primary' : 'secondary'
  }

  return (
    <div className={styles.content__inner}>
      <section className={styles.selects__section}>
        <Text variant='h6' tag='b' color='primary'>
          Размер баннера
        </Text>
        <div className={styles.section_buttons}>
          {bannerSizes.map((size, index) => (
            <Button
              key={`${index}--banner-size`}
              size='large'
              fullWidth
              color={activeColor(index)}
              onClick={() => onChangeBanner(index)}>
              {size}
            </Button>
          ))}
        </div>
      </section>

      <section className={styles.selects__section}>
        <Text variant='h6' tag='b' color='primary'>
          Введите промокод
        </Text>
        <div className={styles.section_buttons}>
          <div className={styles.section__field}>
            <Input size='large' value={code} placeholder='Промокод' onChange={onChangePromo} />
          </div>
          <div className={styles.section_button}>
            <a
              href={
                !isDisabled ? `images/materials/download/${bannerSizes[selectedBanner]}.png` : false
              }
              download={!isDisabled}>
              <Button
                fullWidth
                size='large'
                color='gold-secondary'
                onClick={createPromo}
                loading={loadingCreate}
                disabled={isDisabled}>
                СКАЧАТЬ
              </Button>
            </a>
          </div>
        </div>
      </section>
    </div>
  )
}

export default React.memo(Component)
