import * as React from 'react'
import { SVGAttributes } from 'react'
import { ArrowRoundedIcon as arrow_rounded } from './ArrowRounded'
import { ArrowIcon as arrow } from './Arrow'
import { BurgerIcon as burger } from './Burger'
import { CloseIcon as close } from './Close'
import { DeclineRoundedIcon as decline_rounded } from './DeclineRounded'
import { DollarRoundedIcon as dollar_rounded } from './DollarRounded'
import { DownloadIcon as download } from './Download'
import { LogoutIcon as logout } from './Logout'
import { MailIcon as mail } from './Mail'
import { MinusIcon as minus } from './Minus'
import { PercentRoundedIcon as percent_rounded } from './PercentRounded'
import { PlusIcon as plus } from './Plus'
import { QuestionRoundedIcon as question_rounded } from './QuestionRounded'
import { RubleRoundedIcon as ruble_rounded } from './RubleRounded'
import { StarIcon as star } from './Star'
import { SteamIcon as steam } from './Steam'
import { SuccessRoundedIcon as success_rounded } from './SuccessRounded'
import { UserIcon as user } from './User'

export type IconName =
  | 'arrow_rounded'
  | 'arrow'
  | 'burger'
  | 'close'
  | 'decline_rounded'
  | 'dollar_rounded'
  | 'download'
  | 'logout'
  | 'mail'
  | 'minus'
  | 'percent_rounded'
  | 'plus'
  | 'question_rounded'
  | 'ruble_rounded'
  | 'star'
  | 'steam'
  | 'success_rounded'
  | 'user'

export const iconSet: {
  [key in IconName]: React.FC<SVGAttributes<SVGElement> & { size?: number }>
} = {
  arrow_rounded,
  arrow,
  burger,
  close,
  decline_rounded,
  dollar_rounded,
  download,
  logout,
  mail,
  minus,
  percent_rounded,
  plus,
  question_rounded,
  ruble_rounded,
  star,
  steam,
  success_rounded,
  user
}
