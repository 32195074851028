import * as React from 'react'
import { SVGAttributes } from 'react'

export const DollarRoundedIcon = React.memo(
  ({
    size = 24,
    color = '#4E5361',
    ...props
  }: SVGAttributes<SVGElement> & {
    size?: number
  }) => {
    return (
      <svg width={size} height={size} viewBox='0 0 24 24' fill='none' {...props}>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12 21a9 9 0 100-18 9 9 0 000 18zm.656-12.928a4.442 4.442 0 012.009.789l-.724 1.263a4.588 4.588 0 00-1.339-.637v1.642c1.642.443 2.344 1.101 2.344 2.311v.022c0 1.21-.93 1.987-2.29 2.127v1.07h-1.123v-1.091a5.426 5.426 0 01-2.549-1.026l.843-1.232c.583.443 1.123.746 1.76.875v-1.717c-1.555-.421-2.322-1.004-2.322-2.29v-.021c0-1.188.907-1.977 2.268-2.117v-.626h1.123v.658zm.713 5.476v-.022c0-.356-.184-.583-.767-.799v1.512c.508-.075.767-.302.767-.691zm-2.527-3.488v.021c0 .346.15.562.745.778V9.39c-.53.065-.745.324-.745.67z'
          fill={color}
        />
      </svg>
    )
  }
)

DollarRoundedIcon.displayName = 'DollarRoundedIcon'
