import React from 'react'
import Script from 'next/script'

const id = process.env.NEXT_PUBLIC_JIVO_CHAT_WIDJET_ID || ''

const SupportWidget = () => {
  if (!id) {
    return null
  }

  return <Script id='jivo-site' src={`//code.jivo.ru/widget/${id}`} strategy='afterInteractive' />
}

export default SupportWidget
