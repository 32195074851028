import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUser } from '@/types/user'
import { INotification } from '@/types/notification'

export interface ProfileState {
  userInfo: {
    data: IUser | null
    notifications: INotification[]
  }
}

const initialState: ProfileState = {
  userInfo: {
    data: null,
    notifications: []
  }
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    CLEAR_PROFILE_DATA: state => {
      state.userInfo = { ...initialState.userInfo }
    },
    SET_PROFILE_DATA: (state, action: PayloadAction<any>) => {
      state.userInfo.data = action.payload
      state.userInfo.notifications = action.payload.notifications
    }
  }
})

export const authReducer = authSlice.reducer

export const { CLEAR_PROFILE_DATA, SET_PROFILE_DATA } = authSlice.actions
