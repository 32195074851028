enum apiPrefixEnum {
  routePrefix = '/api',
  auth = '/auth',
  settings = '/settings',
  payment = '/payment',
  market = '/market',
  profile = '/profile',
  user = '/user',
  box = '/box',
  contracts = '/contracts',
  category = '/categories',
  skins = '/skins',
  case = '/case',
  upgrade = '/upgrade',
  notifications = '/notifications'
}

enum errorsNameEnum {
  INTERNAL_SERVER_ERROR = 'InternalServerError',
  UNAUTHORIZED = 'UnauthorizedError',
  BAD_REQUEST = 'BadRequestError',
  NOT_FOUND = 'NotFoundError'
}

export enum serverErrorMessages {
  serverError = 'Ошибка сервера во время выполнения запроса!',
  unauthorized = 'Требуется авторизация!',
  badRequest = 'Нет доступа!',
  unknownError = 'Неизвестная ошибка!'
}

const passportCookieName = 'id'

const minMoneyBet = 20
const initialLastDropCount = 30
const upgradeLimitPrice = 20

export {
  apiPrefixEnum,
  errorsNameEnum,
  passportCookieName,
  minMoneyBet,
  initialLastDropCount,
  upgradeLimitPrice
}
