import React from 'react'

import styles from './styles.module.scss'

interface Props {
  icon: React.ReactNode
  rotate?: number
  responsive?: boolean
}

const Component: React.FC<Props> = ({ icon, rotate = 0, responsive = false }) => {
  return (
    <div className={styles.icon__wrapper} style={{ transform: `rotate(${rotate}deg)` }}>
      {icon}
    </div>
  )
}

export const IconWrapper = React.memo(Component)
