import React from 'react'
import ym, { YMInitializer } from 'react-yandex-metrika'
import { Router } from 'next/router'

interface Props {
  children: React.ReactNode
}

const WithYandexMetric: React.FC<Props> = ({ children }) => {
  const ymAccount = Number(process.env.NEXT_PUBLIC_YM_CLIENT)

  const hit = React.useCallback((url: string) => {
    if (ymAccount) {
      ym('hit', url)
    } else {
      console.log(`%c[YandexMetrika](HIT)`, `color: orange`, url)
    }
  }, [])

  React.useEffect(() => {
    if (ymAccount) {
      hit(window.location.pathname + window.location.search)

      Router.events.on('routeChangeComplete', (url: string) => hit(url))
    }
  }, [ymAccount])

  return (
    <>
      {ymAccount ? (
        <YMInitializer
          version='2'
          accounts={[ymAccount]}
          options={{
            defer: true,
            webvisor: true,
            trackLinks: true,
            accurateTrackBounce: true
          }}
        />
      ) : null}
      {children}
    </>
  )
}

export default WithYandexMetric
