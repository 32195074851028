import Head from 'next/head'
import React from 'react'
import { useTranslation } from 'next-i18next'

const Header: React.FC = () => {
  const { t } = useTranslation('common')

  console.log(t)

  return (
    <Head>
      <meta name='viewport' content='initial-scale=1, maximum-scale=1, user-scalable=no' />
      <title>{t('siteName')}</title>
      <meta name='description' content={t('siteDescription')} />
      <meta name='viewport' content='width=device-width, user-scalable=no' />
    </Head>
  )
}

export default React.memo(Header)
