import { AxiosInstance } from 'axios'
import { IPagination, IPaginationQuery } from '@/types/pagination'
import { Promo, PromoSummary } from '@/types/promo'
import { ITransaction } from '@/types/transactions'

interface PromoCodesResponse extends IPagination {
  rows: PromoSummary[]
}

interface PromoCodeSummaryResponse extends IPagination {
  promo: Promo
  summary: {
    activated: number
    deposited: number
    earned: number
  }
}

interface PromoCodeTransactionsResponse extends IPagination {
  rows: ITransaction[]
}

interface CreatePromoParams {
  code: string
}

export interface PromoResourceInterface {
  getPromoCode: (promoId: number) => Promise<PromoCodeSummaryResponse>
  getPromoCodeTransactions: (
    promoId: number,
    params: IPaginationQuery
  ) => Promise<PromoCodeTransactionsResponse>
  getPromoCodes: (params: IPaginationQuery) => Promise<PromoCodesResponse>
  createPromo: (data: CreatePromoParams) => Promise<any>
}

export const promoResource = (instance: AxiosInstance): PromoResourceInterface => {
  const defaultPagination = { page: 1, limit: 10 }

  return {
    async getPromoCodeTransactions(
      promoId: number,
      params: IPaginationQuery
    ): Promise<PromoCodeTransactionsResponse> {
      const { data } = await instance.get(`/promocodes/${promoId}/transactions`, { params })

      return data
    },

    async getPromoCode(promoId: number): Promise<PromoCodeSummaryResponse> {
      const { data } = await instance.get(`/promocodes/${promoId}`)

      return data
    },

    async getPromoCodes(params: IPaginationQuery = defaultPagination): Promise<PromoCodesResponse> {
      const { data } = await instance.get('/promocodes', { params })

      return data
    },

    async createPromo(form: CreatePromoParams) {
      const { data } = await instance.post('/promocodes', form)

      return data
    }
  }
}
