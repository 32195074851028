import React, { InputHTMLAttributes } from 'react'

import styles from './styles.module.scss'

import { themeSize } from '@/styles/theme'

// @ts-ignore
interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  renderLabel?: () => React.ReactNode
  prepend?: React.ReactNode
  append?: React.ReactNode
  size?: themeSize
}

const Component: React.FC<Props> = ({
  label,
  renderLabel,
  prepend,
  append,
  size = 'medium',
  ...props
}) => {
  return (
    <label className={styles.input}>
      {label && <div className={styles.input__label}>{label}</div>}
      {renderLabel && renderLabel()}

      <div className={styles.input__control}>
        {prepend && <div className={styles.control__prepend}>{prepend}</div>}
        <input className={`${styles.control} ${styles[`size--${size}`]}`} {...props} />
        {append && <div className={styles.control__append}>{append}</div>}
      </div>
    </label>
  )
}

export const Input = React.memo(Component)
