import { AxiosInstance } from 'axios'

export interface AppResourceInterface {
  settings: () => Promise<any>
  readNotifications: () => Promise<any>
}

export const appResource = (instance: AxiosInstance): AppResourceInterface => {
  return {
    settings: async (): Promise<any> => {
      const { data } = await instance.get('/settings')

      return data
    },

    readNotifications: async (): Promise<any> => {
      const { data } = await instance.get('/settings')

      return data
    }
  }
}
