import React from 'react'

import styles from '../styles.module.scss'

import { Icon } from '@/components/icons/icon'
import { IconWrapper } from '@/components/icon-wrapper'
import { useTranslation } from 'next-i18next'
import { alertEnum } from '@/types/alerts'
import { Text } from '@/components/text'

interface Props {
  notification: {
    i18nKey: string
    params: object
  }
  type: alertEnum
  isFirst: boolean
  onClose: () => void
  isShow: boolean
  onClick: () => void
  onAnimationEndHandler: () => void
}

const DefaultAlert: React.FC<Props> = ({
  type,
  notification,
  isShow,
  isFirst,
  onClick,
  onAnimationEndHandler
}) => {
  const { t: tError } = useTranslation('errors')
  const { t: tAlert } = useTranslation('alerts')

  const { i18nKey, params } = notification

  const className = `
    ${styles.alert} 
    ${styles[`type--${type}`]}
    ${styles[isShow ? 'show' : 'hide']}
   `

  return (
    <div className={className} onClick={onClick}>
      <div className={styles.icon__container}>
        <IconWrapper
          icon={
            <Icon
              name={type === alertEnum.error ? 'decline_rounded' : 'success_rounded'}
              color={type === alertEnum.error ? 'red' : 'green'}
            />
          }
        />
      </div>

      <div className={styles.content__container}>
        <Text variant='h6' color='primary'>
          {type === alertEnum.error ? tError(i18nKey, params) : tAlert(i18nKey, params)}
        </Text>

        <div className={styles.close__indicator}>
          <div className={styles.indicator} onAnimationEnd={onAnimationEndHandler} />
        </div>
      </div>
    </div>
  )
}

export default React.memo(DefaultAlert)
