import * as React from 'react'
import { SVGAttributes } from 'react'

export const ArrowIcon = React.memo(
  ({
    size = 24,
    color = '#4E5361',
    ...props
  }: SVGAttributes<SVGElement> & {
    size?: number
  }) => {
    return (
      <svg width={size} height={size} viewBox='0 0 24 24' fill='none' {...props}>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M16.623 19.61a1.368 1.368 0 000-1.886L11.104 12l5.52-5.724a1.369 1.369 0 000-1.885 1.254 1.254 0 00-1.819 0l-6.428 6.666a1.368 1.368 0 000 1.886l6.428 6.667c.502.52 1.316.52 1.818 0z'
          fill={color}
        />
      </svg>
    )
  }
)

ArrowIcon.displayName = 'ArrowIcon'
