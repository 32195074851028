import React from 'react'

import styles from './styles.module.scss'
import { CommonProps } from '@/components/button/types'

interface Props extends CommonProps {}

const ButtonComponent: React.FC<Props> = ({
  children,
  color = 'secondary',
  size = 'medium',
  before,
  after,
  disabled = false,
  loading = false,
  fullWidth = false,
  onClick
}) => {
  const className = `
    ${styles.button} 
    ${styles[`color--${color}`]} 
    ${styles[`size--${size}`]} 
    ${styles[fullWidth ? 'full-width' : 'fit-content']}
    ${styles[loading ? 'is-loading' : '']}
  `

  return (
    <button className={className} onClick={onClick} disabled={disabled}>
      {loading && <div className={styles.button__loading}>LOADING</div>}

      <div className={styles.button__content}>
        {before && before}
        {children}
        {after && after}
      </div>
    </button>
  )
}

export const Button = React.memo(ButtonComponent)
