import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import { authReducer } from '@/store/auth/auth'
import { globalReducer } from '@/store/global/global'

import { createWrapper } from 'next-redux-wrapper'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

export function makeStore() {
  return configureStore({
    reducer: {
      auth: authReducer,
      global: globalReducer
    }
  })
}

const store = makeStore()

export type RootStore = ReturnType<typeof makeStore>
export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action<string>>

export default store

export const wrapper = createWrapper<RootStore>(makeStore)

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector
