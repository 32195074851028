import { AxiosInstance } from 'axios'
import { apiPrefixEnum } from '@/constants/backend'

export interface AuthResourceInterface {
  logout: () => Promise<any>
  getMe: () => Promise<any>
  trap: (userInfo: object) => Promise<any>
}

export const authResource = (instance: AxiosInstance): AuthResourceInterface => {
  return {
    logout: async (): Promise<any> => {
      const { data } = await instance.delete(`${apiPrefixEnum.auth}/logout`)

      return data
    },

    getMe: async () => {
      const { data } = await instance.get(`${apiPrefixEnum.auth}/me`)

      return data
    },

    trap: async (userInfo): Promise<any> => {
      const { data } = await instance.post(`${apiPrefixEnum.auth}/screen`, userInfo)

      return data
    }
  }
}
