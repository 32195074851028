import { AxiosInstance } from 'axios'
import { PartnerLevel } from '@/types/partner'

export interface PartnerSummaryResponse {
  registered: number
  earned: number
  withdrawn: number
}

export interface PartnerLevelResponse {
  levels: PartnerLevel[]
}

export interface PartnerResourceInterface {
  getSummary: () => Promise<PartnerSummaryResponse>
  getLevels: () => Promise<PartnerLevelResponse>
}

export const partnerResource = (instance: AxiosInstance): PartnerResourceInterface => {
  return {
    async getSummary(): Promise<PartnerSummaryResponse> {
      const { data } = await instance.get('/partners/summary')

      return data
    },

    async getLevels(): Promise<PartnerLevelResponse> {
      const { data } = await instance.get('/partners/levels')

      return data
    }
  }
}
