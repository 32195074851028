import { AxiosInstance } from 'axios'

import { apiPrefixEnum } from '@/constants/backend'

export interface NotificationsResourceInterface {
  readNotifications: (ids: number[]) => Promise<any>
}

export const notificationsResource = (instance: AxiosInstance) => {
  return {
    async readNotifications(ids: number[]): Promise<any> {
      const { data } = await instance.post(`${apiPrefixEnum.notifications}/read`, { ids })

      return data
    }
  }
}
