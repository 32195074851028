import { AxiosInstance } from 'axios'
import { WithdrawMethod, WithdrawOrder, WithdrawOrderMethod } from '@/types/withdraw'
import { IPagination, IPaginationQuery } from '@/types/pagination'

interface WithdrawMethodsResponse {
  methods: WithdrawMethod[]
}

interface WithdrawCreateOrderResponse {}

interface WithdrawGetOrderResponse extends IPagination {
  rows: WithdrawOrder[]
}

export type WithdrawResourceInterface = {
  getMethods: () => Promise<WithdrawMethodsResponse>
  createOrder: (
    method: WithdrawOrderMethod,
    amount: number,
    accountNumber: string
  ) => Promise<WithdrawCreateOrderResponse>
  getOrders: (params: IPaginationQuery) => Promise<WithdrawGetOrderResponse>
}

export const withdrawResource = (instance: AxiosInstance): WithdrawResourceInterface => {
  const defaultPagination = { page: 1, limit: 10 }

  return {
    async createOrder(
      method: WithdrawOrderMethod,
      amount: number,
      accountNumber: string
    ): Promise<WithdrawCreateOrderResponse> {
      const { data } = await instance.post('/withdrawals', { method, amount, accountNumber })

      return data
    },

    async getOrders(
      params: IPaginationQuery = defaultPagination
    ): Promise<WithdrawGetOrderResponse> {
      const { data } = await instance.get('/withdrawals', { params })

      return data
    },

    async getMethods(): Promise<WithdrawMethodsResponse> {
      const { data } = await instance.get(`/withdrawals/methods`)

      return { methods: data }
    }
  }
}
