import * as React from 'react'
import { SVGAttributes } from 'react'

export const UserIcon = React.memo(
  ({
    size = 24,
    color = '#4E5361',
    ...props
  }: SVGAttributes<SVGElement> & {
    size?: number
  }) => {
    return (
      <svg width={size} height={size} viewBox='0 0 24 24' fill='none' {...props}>
        <path
          d='M12.613 11.67c-1.19 0-2.22-.427-3.061-1.27a4.197 4.197 0 01-1.269-3.065c0-1.19.427-2.222 1.269-3.065A4.188 4.188 0 0112.613 3c1.19 0 2.22.427 3.062 1.27a4.197 4.197 0 011.269 3.065 4.197 4.197 0 01-1.269 3.066 4.188 4.188 0 01-3.062 1.27zm-7.577 5.171c.025-.35.074-.733.146-1.137a8.97 8.97 0 01.28-1.144c.116-.363.274-.722.469-1.066.203-.357.44-.668.708-.924.28-.268.622-.483 1.017-.64a3.512 3.512 0 011.299-.235c.183 0 .36.075.703.299.211.137.458.297.734.473.235.15.554.29.948.418.385.125.775.188 1.16.188.386 0 .776-.063 1.16-.188a4.19 4.19 0 00.949-.418c.273-.175.52-.334.734-.474.342-.223.52-.298.703-.298.468 0 .905.079 1.299.235.395.157.737.372 1.017.64.267.256.505.567.708.924.195.344.353.703.47 1.066.111.352.205.737.278 1.144.073.403.122.786.146 1.137.024.345.036.702.036 1.063 0 .94-.298 1.7-.887 2.262-.58.553-1.35.834-2.285.834H8.171c-.934 0-1.703-.28-2.284-.834C5.298 19.605 5 18.844 5 17.904c0-.363.012-.72.036-1.063z'
          fill={color}
        />
      </svg>
    )
  }
)

UserIcon.displayName = 'UserIcon'
