import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { io, Socket } from 'socket.io-client'
import { apiUrl, socketUrl } from '@/utils/urls'
import { ApiService, createInstance } from '@/services/api'

type DataSourcesContextValue = {
  socket: Socket | null
  api: ApiService
}

export const DataSourcesContext = React.createContext<DataSourcesContextValue>(
  // @ts-ignore
  {}
)

export interface DataSourcesProviderProps {
  children: React.ReactNode
}

const useRestApiClient = () => {
  return useMemo(() => createInstance(apiUrl()), [])
}

const useSocketClient = () => {
  const [socket, setSocket] = useState<Socket | null>(null)

  useEffect(() => {
    const latestSocket = io(socketUrl(), {
      withCredentials: true,
      transports: ['websocket', 'polling'],
      extraHeaders: {
        referrer: document.referrer
      },
      reconnectionDelay: 5000
    })

    setSocket(latestSocket)

    function cleanup() {
      latestSocket.disconnect()
    }

    return cleanup
  }, [])

  return socket
}

export const DataSourcesProvider: React.FC<DataSourcesProviderProps> = ({ children }) => {
  const socket = useSocketClient()
  const api = useRestApiClient()

  return (
    <DataSourcesContext.Provider value={{ socket, api }}>{children}</DataSourcesContext.Provider>
  )
}

export const useDataSourcesProvider = (): DataSourcesContextValue => {
  return React.useContext(DataSourcesContext)
}
