import * as React from 'react'
import { SVGAttributes } from 'react'

export const PercentRoundedIcon = React.memo(
  ({
    size = 24,
    color = '#4E5361',
    ...props
  }: SVGAttributes<SVGElement> & {
    size?: number
  }) => {
    return (
      <svg width={size} height={size} viewBox='0 0 24 24' fill='none' {...props}>
        <path
          d='M9.737 11c-.077.127-.176.191-.298.191-.121 0-.224-.064-.307-.192-.077-.134-.115-.349-.115-.643 0-.294.038-.506.115-.634.083-.134.186-.201.307-.201.122 0 .221.067.298.201.083.128.125.34.125.634s-.042.509-.125.643zm4.925 3.167c-.077.128-.176.192-.298.192s-.224-.064-.307-.192c-.077-.134-.115-.349-.115-.643 0-.294.038-.506.115-.634.083-.134.185-.201.307-.201s.22.067.298.201c.083.128.124.34.124.634s-.041.509-.124.643z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12 21a9 9 0 100-18 9 9 0 000 18zm-3.847-9.271c.333.333.761.499 1.286.499s.954-.166 1.287-.5c.332-.332.499-.79.499-1.372 0-.39-.077-.726-.23-1.008a1.557 1.557 0 00-.644-.643 1.867 1.867 0 00-.912-.221c-.339 0-.646.074-.921.22-.27.148-.48.362-.634.644-.154.282-.23.618-.23 1.008 0 .582.166 1.04.499 1.373zm5.585-3.149a.67.67 0 00-.553.292l-3.674 5.38a.67.67 0 001.107.756l3.674-5.38a.67.67 0 00-.553-1.048zm-.66 6.317c.332.333.761.499 1.286.499s.954-.166 1.286-.5c.333-.332.5-.79.5-1.372 0-.39-.077-.726-.23-1.008a1.557 1.557 0 00-.644-.643 1.867 1.867 0 00-.912-.221c-.34 0-.646.074-.922.22-.268.148-.48.362-.633.644-.154.282-.23.618-.23 1.008 0 .582.166 1.04.499 1.373z'
          fill={color}
        />
      </svg>
    )
  }
)

PercentRoundedIcon.displayName = 'PercentRoundedIcon'
