import { AxiosInstance } from 'axios'
import { IPagination, IPaginationQuery } from '@/types/pagination'
import { ITransaction } from '@/types/transactions'

interface TransactionsResponse extends IPagination {
  rows: ITransaction[]
}

interface PartnerTransactionsSummaryResponse extends IPagination {
  deposited: number
  activated: number
  earned: number
}

export interface TransactionsResourceInterface {
  getTransactions: (params: IPaginationQuery) => Promise<TransactionsResponse>
  getPartnerSummary: () => Promise<PartnerTransactionsSummaryResponse>
}

export const transactionsResource = (instance: AxiosInstance): TransactionsResourceInterface => {
  const defaultPagination = { page: 1, limit: 10 }

  return {
    async getTransactions(
      params: IPaginationQuery = defaultPagination
    ): Promise<TransactionsResponse> {
      const { data } = await instance.get('/transactions', { params })

      return data
    },

    async getPartnerSummary(): Promise<PartnerTransactionsSummaryResponse> {
      const { data } = await instance.get('/transactions/summary')

      return data
    }
  }
}
