import React from 'react'

import styles from './styles.module.scss'
import { themeVariant, themeColor } from '@/styles/theme'

type tagType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'div' | 'span' | 'b' | 'strong'

interface Props {
  children: React.ReactNode
  color?: themeColor
  variant?: themeVariant
  tag?: tagType
  inline?: boolean
  noWrap?: boolean
  uppercase?: boolean
}

const Component: React.FC<Props> = ({
  children,
  tag = 'div',
  color = 'secondary',
  variant = 'div',
  inline = false,
  noWrap = false,
  uppercase = false,
}) => {
  const Component = tag

  const className = `
    ${styles.text} 
    ${styles[`color--${color}`]} 
    ${styles[`variant--${variant}`]} 
    ${inline ? styles.inline : ''}
    ${noWrap ? styles.noWrap : ''}
    ${uppercase ? styles.uppercase : ''}
  `

  return <Component className={className}>{children}</Component>
}

export const Text = React.memo(Component)
