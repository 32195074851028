import * as React from 'react'
import { SVGAttributes } from 'react'

export const BurgerIcon = React.memo(
  ({
    size = 24,
    color = '#4E5361',
    ...props
  }: SVGAttributes<SVGElement> & {
    size?: number
  }) => {
    return (
      <svg width={size} height={size} viewBox='0 0 24 24' fill='none' {...props}>
        <rect y={3} width={23} height={2} rx={1} fill={color} />
        <rect y={11} width={23} height={2} rx={1} fill={color} />
        <rect y={19} width={13} height={2} rx={1} fill={color} />
      </svg>
    )
  }
)

BurgerIcon.displayName = 'BurgerIcon'
