import * as React from 'react'
import { SVGAttributes } from 'react'

export const SteamIcon = React.memo(
  ({
    size = 24,
    color = '#4E5361',
    ...props
  }: SVGAttributes<SVGElement> & {
    size?: number
  }) => {
    return (
      <svg width={size} height={size} viewBox='0 0 24 24' fill='none' {...props}>
        <path
          d='M11.985 3a8.997 8.997 0 00-8.968 8.277l4.823 1.995c.41-.279.902-.442 1.435-.442l.14.002 2.146-3.105v-.044a3.394 3.394 0 013.392-3.392 3.4 3.4 0 013.395 3.395 3.397 3.397 0 01-3.395 3.392h-.076l-3.059 2.183.003.12a2.543 2.543 0 01-2.543 2.546 2.562 2.562 0 01-2.5-2.045l-3.45-1.43c1.07 3.78 4.535 6.549 8.657 6.549a8.998 8.998 0 008.998-9c0-4.972-4.029-9-8.998-9V3zm-3.33 13.658l-1.106-.457c.197.407.536.747.988.937a1.915 1.915 0 002.499-1.03 1.9 1.9 0 00.003-1.463 1.89 1.89 0 00-1.035-1.037 1.9 1.9 0 00-1.406-.023l1.14.471a1.41 1.41 0 01-1.084 2.602zm8.56-6.978a2.264 2.264 0 00-2.26-2.262 2.263 2.263 0 000 4.523 2.262 2.262 0 002.26-2.261zm-3.952-.003c0-.94.758-1.7 1.696-1.7a1.7 1.7 0 011.702 1.7c0 .938-.764 1.7-1.702 1.7a1.698 1.698 0 01-1.696-1.7z'
          fill={color}
        />
      </svg>
    )
  }
)

SteamIcon.displayName = 'SteamIcon'
