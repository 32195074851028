import { FC } from 'react'

import styles from './styles.module.scss'

interface IProps {
  title?: string
  text?: string
}

const ErrorPage: FC<IProps> = ({ title, text }) => {
  return (
    <div className={styles.errorPage__content}>
      <div className={styles.subTitle}>{title}</div>
      <div className={styles.subTitle}>{text}</div>
    </div>
  )
}

export default ErrorPage
