import React from 'react'

import DefaultAlert from './default-alert'
import { alertEnum } from '@/types/alerts'

interface INotificationParams {
  [key: string]: string
}

interface IProps {
  type: alertEnum
  variant: string
  isFirst: boolean
  i18nKey: string
  params: INotificationParams
  onClose: () => void
}

const Component: React.FC<IProps> = ({ isFirst, type, variant, i18nKey, params = {}, onClose }) => {
  const [show, setShow] = React.useState(true)

  const onAnimationEndHandler = () => {
    setShow(false)

    setTimeout(onClose, 300)
  }

  const onClickHandler = () => {
    setShow(false)

    setTimeout(onClose, 300)
  }

  const notification = {
    variant,
    type,
    i18nKey,
    params
  }

  return (
    <DefaultAlert
      notification={notification}
      type={type}
      isFirst={isFirst}
      isShow={show}
      onClick={onClickHandler}
      onAnimationEndHandler={onAnimationEndHandler}
      onClose={onClose}
    />
  )
}

export const Alert = React.memo(Component)
