import React from 'react'
import Modal from './Modal'
import { TypeModal } from '@/components/modal/types'
import Editor from '@/components/modals/editor'
import { connect } from 'react-redux'
import { useAppDispatch } from '@/store'
import { CLOSE_MODAL } from '@/store/global/global'

const Component: React.FC = props => {
  const dispatch = useAppDispatch()
  // @ts-ignore
  const { type, isOpen } = props.modal
  if (!isOpen) return null

  const closeModal = () => dispatch(CLOSE_MODAL())

  switch (type) {
    case TypeModal.editor:
      return (
        <Modal closeModal={closeModal} title='РЕДАКТОР'>
          <Editor />
        </Modal>
      )

    default:
      return <></>
  }
}

// @ts-ignore
const mapStateToProps = props => {
  const { modal } = props.global

  return {
    modal
  }
}

export default connect(mapStateToProps)(React.memo(Component))
