import * as React from 'react'
import { SVGAttributes } from 'react'

export const CloseIcon = React.memo(
  ({
    size = 24,
    color = '#4E5361',
    ...props
  }: SVGAttributes<SVGElement> & {
    size?: number
  }) => {
    return (
      <svg width={size} height={size} viewBox='0 0 16 16' fill='none' {...props}>
        <path
          d='M1 1l7 7m7 7L8 8m0 0l7-7M8 8l-7 7'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )
  }
)

CloseIcon.displayName = 'CloseIcon'
