import React from 'react'

import styles from './styles.module.scss'

import { Alert } from './alert'

import { CLOSE_ALERT } from '@/store/global/global'
import { useAppDispatch, useAppSelector } from '@/store'

const Component: React.FC = () => {
  const dispatch = useAppDispatch()
  const alerts = useAppSelector(({ global }) => global.alerts)

  const closeAlertHandlerCreator = React.useCallback((id: number) => {
    return () => {
      dispatch(CLOSE_ALERT(id))
    }
  }, [])

  if (alerts.length === 0) return null

  return (
    <div className={styles.alerts__container}>
      {alerts.map((alert, i) => (
        <Alert
          isFirst={i === 0}
          onClose={closeAlertHandlerCreator(alert.id)}
          type={alert.type}
          variant={alert.variant}
          i18nKey={alert.i18nKey}
          params={alert.params}
          key={alert.id}
        />
      ))}
    </div>
  )
}

export const AlertsProvider = React.memo(Component)
