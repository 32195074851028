import axios from 'axios'
import { userResource, UserResourceInterface } from '@/services/api/resources/user'
import { promoResource, PromoResourceInterface } from '@/services/api/resources/promo'
import { authResource, AuthResourceInterface } from '@/services/api/resources/auth'
import { profileResource, ProfileResourceInterface } from '@/services/api/resources/profile'
import { paymentResource, PaymentResourceInterface } from '@/services/api/resources/payment'
import { appResource, AppResourceInterface } from '@/services/api/resources/app'
import {
  notificationsResource,
  NotificationsResourceInterface
} from '@/services/api/resources/notifications'
import { withdrawResource, WithdrawResourceInterface } from '@/services/api/resources/withdraw'
import {
  transactionsResource,
  TransactionsResourceInterface
} from '@/services/api/resources/transactions'
import { partnerResource, PartnerResourceInterface } from '@/services/api/resources/partner'

export interface ApiService {
  app: AppResourceInterface
  user: UserResourceInterface
  promo: PromoResourceInterface
  auth: AuthResourceInterface
  profile: ProfileResourceInterface
  payment: PaymentResourceInterface
  notifications: NotificationsResourceInterface
  withdraw: WithdrawResourceInterface
  transactions: TransactionsResourceInterface
  partner: PartnerResourceInterface
}

export const createInstance = (url: string): ApiService => {
  const axiosInstance = axios.create({
    baseURL: `${url}/api`,
    withCredentials: true
  })

  return {
    app: appResource(axiosInstance),
    user: userResource(axiosInstance),
    promo: promoResource(axiosInstance),
    auth: authResource(axiosInstance),
    profile: profileResource(axiosInstance),
    payment: paymentResource(axiosInstance),
    notifications: notificationsResource(axiosInstance),
    withdraw: withdrawResource(axiosInstance),
    transactions: transactionsResource(axiosInstance),
    partner: partnerResource(axiosInstance)
  }
}
